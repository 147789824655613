import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import headshot from '../content/headshot.JPEG';

import { LinkContainer } from 'react-router-bootstrap';

const HomePage = () => (
    <>
        <Container className='py-4'>
            <div className="p-5 mb-4 bg-light rounded-3">
                <Container fluid className="py-5">
                    <Row className="flex-lg-row-reverse align-items-center g-5">
                    <Col lg={6} sm={8} className="p-0 shadow-lg">
                        <Image rounded fluid src={headshot} alt="A photo of Austen Ariniello" width="720" />
                    </Col>
                    <Col lg={6} className="p-3 p-lg-5 pt-lg-3">
                        <h1 className="display-5 fw-bold">
                            Hello, my name is Austen...
                        </h1>
                        <Row>
                        <Col md='8' className="fs-4 mb-3">
                            and welcome to my website.
                        </Col>
                        </Row>
                        <LinkContainer to={`/education`}>
                            <Button size='lg' variant='primary'>
                                Take a look
                            </Button>
                        </LinkContainer>
                    </Col>
                    </Row>
                </Container>
            </div>

            <Row className="align-items-md-stretch">
                <Col md="6">
                    <div className="h-100 p-5 text-white bg-dark rounded-3">
                        <h2>Experience</h2>
                        <p>Browse through my previous experience as a project manager and software developer.</p>
                        <LinkContainer to={`/experience`}>
                            <Button variant="outline-light">View More</Button>
                        </LinkContainer>
                    </div>
                </Col>
                <Col md="6">
                    <div className="h-100 p-5 bg-light border rounded-3">
                        <h2>Projects</h2>
                        <p>Or, take a look at some of my projects.</p>
                        <LinkContainer to={`/projects-list`}>
                            <Button variant="outline-secondary" type="button">View More</Button>
                        </LinkContainer>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
);

export default HomePage;