import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';

const AddCommentForm = ({articleName, setArticleInfo}) => {

    const [username, setUsername] = useState('');
    const [commentText, setCommentText] = useState('');

    const addComment = async () => {
        const result = await fetch(`/api/articles/${articleName}/add-comment`, {
            method: 'post',
            body: JSON.stringify({username, text: commentText}),
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const body = await result.json();
        setArticleInfo(body);
        setUsername('');
        setCommentText('');
    }

    return (
        <Col md='6'>
        <Form>
            <h3 className="mb-3">Add a Comment</h3>
            <Col md='6'>
            <Form.Group className='mb-3'>
                <FloatingLabel 
                    controlId = 'floatingUsername'
                    label = 'Name'
                    className = 'mb-3'>
                    <Form.Control 
                        type="text" 
                        value={username} 
                        onChange={(event) => setUsername(event.target.value)}
                        placeholder='username' />
                </FloatingLabel>
            </Form.Group>
            </Col>
            <Form.Group className='mb-3'>
                <FloatingLabel 
                    controlId = 'floatingComment'
                    label = 'Comment'
                    className = 'mb-3'>
                    <Form.Control 
                        as='textarea' 
                        style={{ height: '10em' }}
                        value={commentText} 
                        disabled={(username === '') ? true : false}
                        onChange={(event) => setCommentText(event.target.value)}
                        placeholder='comment' />
                </FloatingLabel>
            </Form.Group>
            <Form.Group className='mb-3'>
                <Button onClick={() => addComment()}>Add Comment</Button>
            </Form.Group>
        </Form>
        </Col>
    )
};

export default AddCommentForm;