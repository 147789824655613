import React from 'react';
import ArticlesList from '../components/ArticlesList';
import articleContent from './article-content';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const ArticlesListPage = () => (
    <Container>
        <h1>This is a list of Articles</h1>
        <Row xs={1} md={3} xl={4} className="g-4 align-items-md-stretch">
            <ArticlesList articles={articleContent} />
        </Row>
    </Container>
);

export default ArticlesListPage;