import React from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from "react-bootstrap/InputGroup";
import Badge from "react-bootstrap/Badge";

import { HandThumbsUpFill } from "react-bootstrap-icons";

const UpvoteSection = ({articleName, upvotes, setArticleInfo}) => {

    const upvoteArticle = async () => {
        const result = await fetch(`/api/articles/${articleName}/upvote`, {
            method: 'post',
        });
        const body = await result.json();
        setArticleInfo(body);
    }

    return (
        <Button variant='secondary' id='upvote-button' onClick={() => upvoteArticle()}>
            <HandThumbsUpFill/><Badge bg='secondary' className='ms-2'>{upvotes}</Badge>
        </Button>
    );

}

export default UpvoteSection;

/* 
        <InputGroup className="mb-3">
            <Button variant='secondary' id='upvote-button' onClick={() => upvoteArticle()}><HandThumbsUpFill/></Button>
            <Form.Control
            aria-label="Example text with button addon"
            aria-describedby="basic-addon1"
            placeholder={upvotes}
            readOnly
            disabled
            />
        </InputGroup>
        */