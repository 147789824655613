import React, {useEffect, useState} from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, ButtonGroup, Button } from 'react-bootstrap';
import { Github, Linkedin } from 'react-bootstrap-icons';

const NavBar = () => {

    let location = useLocation();

    const blogLinks = () => (
        <Nav variant="pills" defaultActiveKey='blog-home' className='me-auto'>
            <Nav.Item><NavLink to="blog/welcome" eventKey='blog-home'className='nav-link'>Welcome</NavLink></Nav.Item>
            <Nav.Item><NavLink to="blog/about" className='nav-link'>About</NavLink></Nav.Item>
            <Nav.Item><NavLink to="blog/articles-list" className='nav-link'>Articles</NavLink></Nav.Item>
        </Nav>);

    const homeLinks = () => (
        <Nav variant="pills" className='me-auto' id=''>
            <Nav.Item variant='dark'><NavLink to='education' className='nav-link'>Education</NavLink></Nav.Item>
            <Nav.Item variant='dark'><NavLink to="experience" className='nav-link'>Experience</NavLink></Nav.Item>
            <Nav.Item><NavLink to="projects-list" className='nav-link'>Projects</NavLink></Nav.Item>
        </Nav>);

    const [links, setLinks] = useState(homeLinks);

    useEffect(() => {
        let name = location.pathname;

        if (name.length >= 5) {
            if(name.slice(0, 5) === '/blog') {
                setLinks(blogLinks());
            }
            else {
                setLinks(homeLinks())
            }
        }
        else {
            setLinks(homeLinks())
        }
    }, [location]);

    return (
        <>
            <Navbar id='navbar' collapseOnSelect expand="lg" bg="dark" variant="dark" sticky='top' className='mb-3'>
                <Container>
                    <Navbar.Brand href="/">Home</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    {links}
                    </Navbar.Collapse>
                    <ButtonGroup aria-label='Project links'>
                        <Button variant='outline-secondary' href='https://www.linkedin.com/in/austenariniello/'>
                            <Linkedin />
                        </Button>
                        <Button variant='outline-secondary' href='https://github.com/austenariniello'>
                            <Github />
                        </Button>
                    </ButtonGroup>
                </Container>
            </Navbar>

            <div className='page-body'>
                <Outlet />
            </div>
        </>
    );
};

export default NavBar;