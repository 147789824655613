import React from "react";
import Container from 'react-bootstrap/Container';

const NotFoundPage = () => (
    <Container>
    <h1>404: Page Not Found</h1>
    </Container>
);

export default NotFoundPage;

