import React from "react";
import {LinkContainer} from 'react-router-bootstrap';

// Bootstrap Imports
import Card from "react-bootstrap/Card";
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const ArticlesList = ({articles}) => (
    <>
    {articles.map((article, i) => (
        <Col>
        <Card className="shadow h-100">
            <Card.Body className='d-flex flex-column'>
            <Card.Title>{article.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">by Austen Ariniello</Card.Subtitle>
            <Card.Text>
                {article.content[0].substring(0, 150)}...
            </Card.Text>
            <Card.Link className='stretched-link' href={`/blog/article/${article.name}`}/>
            <LinkContainer to={`/article/${article.name}`}>
                <Button className='align-self-md-end mt-auto'>Read More</Button>
            </LinkContainer>
            </Card.Body>
        </Card>
        </Col>
    ))}
    </>
)

export default ArticlesList;