import React from 'react';
import ExperiencesList from '../components/ExperiencesList';
import jobsContent from '../content/experience-content';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const ExperienceListPage = () => (
    <Container className='pb-4'>
        <h1 className = 'display-4 mb-3'>Experience</h1>
        <Row xs={1} className="g-4 align-items-md-stretch">
            <ExperiencesList jobs={jobsContent} />
        </Row>
    </Container>
);

export default ExperienceListPage;