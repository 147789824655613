import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

// Bootstrap
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Github, DisplayFill } from 'react-bootstrap-icons';

const BlogProjectContent = () => (
    <ButtonGroup aria-label='Project links' size='lg'>
        <LinkContainer to={'/blog/welcome'}>
            <Button variant='outline-secondary'>
                <DisplayFill />
            </Button>
        </LinkContainer>
        <Button variant='outline-secondary' href='https://github.com/austenariniello/my-blog'>
            <Github />
        </Button>
    </ButtonGroup>
);

export default BlogProjectContent;