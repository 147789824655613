import React from 'react';
import { useParams } from 'react-router-dom';
import projectContent from '../content/project-content';
import ProjectsList from '../components/ProjectsList';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ProjectPage = () => {

    const params = useParams();
    const name = params.name;
    const project = projectContent.find((a) => (
        a.name === name
    ));

    const otherProjects = projectContent.filter(project => project.name !== name);

    return (
        <Container>
            <Row>
                <Col><div className = 'display-2'>{project.title}</div></Col>
            </Row>
            <Row>
                <Col><h2 className = 'mb-3'>{project.blurb}</h2></Col>
            </Row>
            <Row>
                <Col md='9'><hr/></Col>
            </Row>
            
            <Row>
                <Col>
                    <Row>
                        <Col md={6}>
                            <ListGroup variant='flush'>
                                {project.content.map((bullet, i) => (
                                    <ListGroup.Item key={"bullet-"+i} className='fs-6'>
                                        {bullet}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                        <Col md={6}>
                            {/* To be used for image in future*/}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col md='9'><hr/></Col>
            </Row>
            
            {project.link_component}
            
            <hr/>


        <h3 className='mb-3'>Other Projects</h3>
        <Row xs={1} md={3} className="g-4 align-items-md-stretch">
            <ProjectsList projects={otherProjects} />
        </Row>
        </Container>
)};

export default ProjectPage;