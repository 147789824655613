import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image'
import ListGroup from "react-bootstrap/ListGroup";


const ProjectsList = ({jobs}) => (
    <>
    {jobs.map((job, i) => (
        <Col>
        <Card className="shadow h-100">
            <Row className='g-0'>
            <Col md={9}>
            <Card.Body className='d-flex flex-column'>
            <Card.Title>
                <Row className='justify-content-between'>
                    <Col>
                        <strong>{job.title}</strong>
                    </Col>
                    <Col className="text-md-end">
                        {job.org}
                    </Col>
                </Row> 
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
                <Row className='justify-content-between'>
                    <Col>
                        {job.start}-{job.end}
                    </Col>
                    <Col className="text-md-end">
                        <em>{job.location}</em>
                    </Col>
                </Row>
            </Card.Subtitle>
            <ListGroup variant='flush'>
                {job.content.map((bullet, i) => (
                    <ListGroup.Item key={"bullet-"+i}>
                        {bullet}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            </Card.Body>
            </Col>
            <Col md={3} className='g-0 my-auto'>
                <Image src={job.img} alt={job.imgAlt} fluid className={'rounded-end border-start ' + job.imgClass}/>
            </Col>
            </Row>
        </Card>
        </Col>
    ))}
    </>
)

export default ProjectsList;
