import React from "react";
import BlogProjectContent from "./blog-project-content";
import SlimeProjectLinks from "./slime-project-content";

const projects = [
    {
        name: 'blog-project',
        title: 'Example Blog Project',
        blurb: 'Blog project',
        content: [
            'Deployed a full-stack blog website from scratch.',
            'Designed a sleek and interactive front-end using ReactJS.',
            'Developed a NodeJS server with Express and MongoDB to support CRUD functionality.'],
        link_component: <BlogProjectContent />,
    },
    {
        name: 'slime-game',
        title: 'The Secret Life of a Slime',
        blurb: 'Digital Simulation Role-Playing Game',
        content: [
            'Collaborated with a multi-functional team of five students to create a video game from scratch in a six-week period.',
            'Conceptualized UX design and refined menu and GUI implementation.',
            'Designed and developed storage/inventory management and achievement system.',
            "Diagnosed and debugged faulty code over multiple iterations of the game's development."],
        link_component: <SlimeProjectLinks />,
    },
    {
        name: 'power-congestion',
        title: 'Analysis of Impact on Power Congestion with Participation of Hybrid PV-Wind Power Plants',
        blurb: 'Senior Thesis Project',
        content: [
            'Independently authored an IEEE-style research paper that concluded that hybrid PV-wind power systems are a promising solution to reduce the power congestion produced by renewable energy power plants.',
            'Studied the hourly behavior of transmission network congestion due to the installation of alternative energy power systems.',
            'Designed simulations of a hybrid 2000-Bus transmission system at different penetration levels using MATLAB and MATPOWER.'],
        link_component: <></>,
    },
    {
        name: 'wind-turbine',
        title: 'Vortex-Induced-Vibrations Bladeless Wind Turbine',
        blurb: 'Research Project with NYU Smart Cities',
        content: [
            'Researched previous bladeless wind turbine case studies and models to construct an improved design.',
            'Developed computer simulations of a spherical movement alternator using MATLAB.',
            'Directed two teams that designed the structural aspect of the project and the generator of the turbine.',
            'Created 3D computer-aided design models for the wind turbine mast and generator.'],
        link_component: <></>,
    },
    {
        name: 'e20-assembler-simulator',
        title: 'E20 Assembler and Simulator',
        blurb: 'Python Program used to translate and execute E20 assembly language.',
        content: [
            'Built a Python program to translate E20 assembly language into machine language and execute assembled files.',
            'Organized and revised program to adhere to proper coding standards and ensure its comprehensibility.'],
        link_component: <></>,
    },
    {
        name: 'library-research',
        title: 'Significance and Usage of Public and Academic Libraries',
        blurb: 'Research Project for Sustainable Urban Environments',
        content: [
            "Collected a mixture of qualitative and quantitative research data about people's relationships with libraries.",
            'Co-authored a research paper on the personal significance of libraries to both children and adults.',
            'Analyzed existing texts and consulted experts about the significance and usage of libraries.',
            'Examined the importance of public and academic libraries in urban environments.'],
        link_component: <></>,
    },
    {
        name: 'nanorobotics-challenge',
        title: 'Nanorobotics Challenge',
        blurb: 'Research Project for Sustainable Urban Environments',
        content: [
            "Served as Chief Software Engineer on a semester-long project with the objective of creating a theoretical nanorobot that can travel throughout the patient's body and diagnose and cure diseases and viruses found in the patient. ",
            "Wrote a program in python that simulated a nanorobot's travel through a digital map of a body to find diseases and viruses to cure.",
            'Designed and printed a 3D scale model of the nanorobot. ',
            'Created a cost estimate and an organized schedule for the project.'],
        link_component: <></>,
    }
];

export default projects;