import React from 'react';
import ProjectsList from '../components/ProjectsList';
import projectContent from '../content/project-content';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const ProjectsListPage = () => (
    <Container>
        <h1 className = 'mb-3 display-4'>Projects</h1>
        <Row xs={1} md={3} className="g-4 align-items-md-stretch">
            <ProjectsList projects={projectContent} />
        </Row>
    </Container>
);

export default ProjectsListPage;