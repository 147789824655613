// React
import React, { useState, useEffect } from 'react';
// React Router
import { useParams } from 'react-router-dom';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import articleContent from './article-content';
import ArticlesList from '../components/ArticlesList';
import CommentsList from '../components/CommentsList';
import UpvoteSection from '../components/UpvoteSection';
import AddCommentForm from '../components/AddCommentForm';



const ArticlePage = () => {

    const params = useParams();
    const name = params.name;
    const article = articleContent.find((a) => (
        a.name === name
    ));

    const [articleInfo, setArticleInfo] = useState({ upvotes: 0, comments: [] });

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`/api/articles/${name}`);
            const body = await result.json();
            setArticleInfo(body);
        }
        fetchData();
    }, [name]);

    const otherArticles = articleContent.filter(article => article.name !== name);

    return (
        <Container>
            <Row className='pb-3'>
                <Col><div className = 'display-2'>{article.title}</div></Col>
            </Row>
            <Row>
                <Col><UpvoteSection articleName={name} upvotes={articleInfo.upvotes} setArticleInfo={setArticleInfo} /></Col>
            </Row>
            <Row>
                <Col md='9'><hr/></Col>
            </Row>
            <Row>
                <Col md = '9' className='fs-5'>
                    {article.content.map((paragraph, i) => (
                        <p style={{textIndent: '3em'}} key={"paragraph-"+i}>
                            {paragraph}
                        </p>
                    ))}
                </Col>
            </Row>
            
            <Row>
                <Col md='9'><hr/></Col>
            </Row>
            <Row>
                <Col><CommentsList comments={articleInfo.comments}/></Col>
            </Row>
            <Row>
                <Col><AddCommentForm articleName={name} setArticleInfo={setArticleInfo} /></Col>
            </Row>
            
            <Row>
                <Col><hr/></Col>
            </Row>

        <h3 className='mb-3'>Other Articles</h3>
        <Row xs={1} md={3} className="g-4 align-items-md-stretch">
            <ArticlesList articles={otherArticles} />
        </Row>
        <hr/>
    </Container>

        
)};

/*
<Container>
        <h1>{article.title}</h1>
        <UpvoteSection articleName={name} upvotes={articleInfo.upvotes} setArticleInfo={setArticleInfo} />
        {article.content.map((paragraph, i) => (
            <p key={"paragraph-"+i}>
                {paragraph}
            </p>
        ))}
        <CommentsList comments={articleInfo.comments}/>
        <AddCommentForm articleName={name} setArticleInfo={setArticleInfo} />
        <h3>Other Articles</h3>
        <Row xs={1} md={3} className="g-4">
            <ArticlesList articles={otherArticles}/>
        </Row>
        </Container> 
*/

export default ArticlePage;