import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import HomePage from './main/pages/HomePage';
import ProjectsListPage from './main/pages/ProjectsListPage';
import ProjectPage from './main/pages/ProjectPage';
import EducationPage from './main/pages/EducationPage';
import ExperiencePage from './main/pages/ExperiencePage';
import NavBar from './main/components/NavBar';

import BlogHomePage from './blog/pages/BlogHomePage';
import AboutPage from './blog/pages/AboutPage';
import ArticlePage from './blog/pages/ArticlePage';
import ArticlesListPage from './blog/pages/ArticleListPage';
import NotFoundPage from './global-components/NotFoundPage';

import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<NavBar />}>
            <Route index element={<HomePage />}/>
            <Route path='education' element={<EducationPage />}/>
            <Route path='experience' element={<ExperiencePage />}/>
            <Route path="projects-list" element={<ProjectsListPage />}/>
            <Route path='project/:name' element={<ProjectPage />}/>
            <Route path="*" element={<NotFoundPage />}/>
            <Route path="blog">
              <Route index element={<BlogHomePage />}/>
              <Route path="welcome" element={<BlogHomePage />}/>
              <Route path="about" element={<AboutPage />}/>
              <Route path="articles-list" element={<ArticlesListPage />}/>
              <Route path="article/:name" element={<ArticlePage />}/>
              <Route path="*" element={<NotFoundPage />}/>
            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
