const jobs = [
    {
        title: 'Junior Project Manager',
        org: 'NYC Department of Design and Construction',
        start:'June 2022',
        end: 'Present',
        location: 'New York, NY',
        img: 'https://upload.wikimedia.org/wikipedia/commons/0/01/NYC_DDC_Logo.png',
        imgAlt: "The letters NYC written in white above the letters DDC written in orange over a blue background. Logo for New York City's Department of Design and Construction: ",
        imgClass: '',
        content: [
            'Modeled, revised, and redesigned building information models for current projects.',
            'Constructed and managed a large database of capital projects',
            'Analyzed observations from site visits to draft recommendations for proposed projects.',
            'Assisted team leaders with troubleshooting project management software.',
            'Inspected job sites to identify and document potential issues with design and construction.'],
    },
    {
        title: 'Programming Languages Teaching Assistant',
        org: 'Tandon School of Engineering: Department of Electrical and Computer Engineering',
        start:'Janurary 2022',
        end: 'May 2022',
        location: 'New York, NY',
        img: 'https://engineering.nyu.edu/sites/default/files/2019-01/tandon_stacked_color.jpg',
        imgAlt: "A purple torch with the letters NYU to its right with the words Tandon School of Engineering written beneath it in black.",
        imgClass: 'p-3',
        content: [
            'Aided students with designing and debugging their programs written in Haskell, Prolog, Forth, and Python.',
            'Provided students with timely and frequent feedback focused on high-level computer science concepts.',
            'Tutored students on topics such as higher-order functions, type inference, monads, object-orientation, exceptions.',
            'Developed and improved lesson plans for 26 students in collaboration with professor and fellow teaching assistant.'],
    },
    {
        title: 'Keyholder',
        org: 'SoulCycle',
        start:'September 2018',
        end: 'February 2022',
        location: 'New York, NY',
        img: 'https://upload.wikimedia.org/wikipedia/en/7/73/Soulcyclelogo.png',
        imgAlt: 'A yellow bicycle wheel with the word SoulCycle overlaid in black',
        imgClass: '',
        content: [
            'Installed and maintained stationary bikes and general facilities.',
            'Diagnosed and assisted with IT problems to ensure day-to-day operations.',
            'Operated an online booking system and communicated with guests.'],
    },
    {
        title: 'Site Leader',
        org: 'NYU Alternative Breaks',
        start:'September 2018',
        end: 'March 2020',
        location: 'New York, NY',
        img: 'https://res.cloudinary.com/scalefunder/image/fetch/s--O_JqpEx6--/f_auto,fl_lossy,q_auto/https://res.cloudinary.com/scalefunder/image/upload/v1550900869/NYU_Rising_Violets/fgnuu0trfxa4myjgw5vs.png',
        imgAlt: 'The letters AB written in white over a black background with the words NYU Alternative Breaks overlaying it.',
        imgClass: '',
        content: [
            'Worked alongside non-profit organizations and community leaders to encourage sustainability at home and abroad.',
            'Fostered sustainable, economic prosperity within vulnerable communities in Costa Rica.',
            'Organized a service trip to Belize that focused on environmental conservation and protection.'],
    }
];

export default jobs;