import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Col from 'react-bootstrap/Col';

const CommentsList = ({ comments }) => (
    <>
    <Col md='9'>
    <h3 className="mb-3">Comments</h3>
    <ListGroup variant="flush">
        {comments.map((comment, key) => (
            <ListGroup.Item className="mb-2" key={key}>
                <h4>{comment.username}</h4>
                <p>{comment.text}</p>
            </ListGroup.Item>
        ))}
    </ListGroup>
    </Col>
    <hr/>
    </>
)

export default CommentsList;