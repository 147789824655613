import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image'
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

const EducationPage = () => (
    <>
        <Container className='py-4'>
            <Container className="my-5">
                <Row className="p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
                    <Col lg={7} className="p-3 p-lg-5 pt-lg-3">
                        {/*
                        <h1 className="display-4 fw-bold lh-1">Border hero with cropped image and shadows</h1>
                        <p className="lead">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
                        <button type="button" className="btn btn-primary btn-lg px-4 me-md-2 fw-bold">Primary</button>
                        <button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>
                        </div>
*/}
                        <Row className='p-0'>
                            <Col md='auto'>
                            <h1 className="display-4 fw-bold lh-1">
                                New York University,
                            </h1>
                            </Col>
                            <Col md='auto'>
                            <h1 className="display-5">
                                Tandon School of Engineering
                            </h1>
                            </Col>
                        </Row>
                        <Row className='justify-content-between p-0'>
                            <Col md='9' className="fs-4">
                                <strong>Electrical and Computer Engineering B.S.</strong>
                            </Col>
                            <Col md='auto' className="fs-4 text-md-end">
                                <em>Cum Laude</em>
                            </Col>
                        </Row>
                        <Row className='justify-content-between'>
                            <Col md='9' className="fs-4">
                                Game Engineering Minor
                            </Col>
                            <Col md='auto' className="fs-4 mb-3 text-md-end">
                                GPA: 3.718
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={{span: 4, offset: 1}} className="p-0 overflow-hidden shadow-lg">
                        <Image className="rounded-3" src="https://engineering.nyu.edu/sites/default/files/styles/content_header_default_1x/public/2018-12/b589aea2-54a0-4fc5-8952-b07a93a4e652.jpg?h=8815feae&itok=vyuzK8ZK" alt="" width="720" />
                    </Col>
                </Row>
            </Container>

            <Row className="align-items-md-stretch">
                <Col className='pb-4' lg="6">
                    <div className="h-100 p-5 bg-light rounded-3 border">
                        <h2 className='pb-2'>Computer Engineering Coursework</h2>
                        <ListGroup className='pb-0' variant='flush'>
                            <ListGroup.Item variant='light' className='bg-light'>Embedded Systems</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Programming Languages</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Algorithms</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Computer Architecture</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Object-Oriented Programming</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Interactive Computer Graphics</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Digital Logic</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>State Machines</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Data Structures</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Human-Computer Interaction</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Game Programming</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Artificial Intelligence</ListGroup.Item> 
                        </ListGroup>
                    </div>
                </Col>
                <Col className='pb-4' lg="6">
                    <div className="h-100 p-5 bg-light border rounded-3">
                        <h2 className='pb-2'>Electrical Engineering Coursework</h2>
                        <ListGroup className='pb-0' variant='flush'>
                            <ListGroup.Item variant='light' className='bg-light'>Electrical Power and Machinery</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Electric Energy Conversion</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Communication Theory</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Electromagnetic Waves</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Electronics</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Signals and Systems</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Sustainable Urban Environments</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Power Distribution</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Transmission Systems</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Electromagnetism</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Probability</ListGroup.Item>
                            <ListGroup.Item variant='light' className='bg-light'>Electrical Circuits</ListGroup.Item> 
                        </ListGroup>
                    </div>
                </Col>
            </Row>
            <Row className="py-1 px-5 mx-0 mb-4 rounded-3 border">
                <Container fluid className="py-5">
                    <Row>
                        <h1 className="display-5">
                            Awards and Honors
                        </h1>
                    </Row>
                    <Row>
                        <Accordion flush>
                            <Accordion.Item eventKey='award-0'>
                                <Accordion.Header>NYU Tandon Dean's List</Accordion.Header>
                                <Accordion.Body>
                                    <Row className='justify-content-between mb-3'>
                                        <Col md='auto'>
                                            Academic honor awarded to undergraduate students who achieved high scholarship at the end of the academic year.
                                        </Col>
                                        <Col md='auto' className='text-md-end'>
                                            May 2021, 2022
                                        </Col>
                                    </Row>
                                    <Button href='https://engineering.nyu.edu/academics/support-services/undergraduate/deans-list'>More Information</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey='award-1'>
                                <Accordion.Header>Myron M. Rosenthal Merit Award</Accordion.Header>
                                <Accordion.Body>
                                    <Row className='justify-content-between mb-3'>
                                        <Col md='auto'>
                                            Honors a select group of electrical and computer engineering majors who demonstrate academic excellence.
                                        </Col>
                                        <Col md='auto' className='text-md-end'>
                                            May 2021
                                        </Col>
                                    </Row>
                                    <Button href='https://engineering.nyu.edu/academics/departments/electrical-and-computer-engineering/students/student-awards#chapter-id-46148'>More Information</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey='award-2'>
                                <Accordion.Header>Tandon Honors Program</Accordion.Header>
                                <Accordion.Body>
                                    <Row className='justify-content-between mb-3'>
                                        <Col md='auto'>
                                            Provides advanced students with rigorous intellectual development through active learning and faculty mentoring.
                                        </Col>
                                        <Col md='auto' className='text-md-end'>
                                            May 2018
                                        </Col>
                                    </Row>
                                    <Button href='http://catalog.poly.edu/content.php?catoid=15&navoid=1203'>More Information</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey='award-3'>
                                <Accordion.Header>Tandon Scholarship</Accordion.Header>
                                <Accordion.Body>
                                    <Row className='justify-content-between mb-3'>
                                        <Col md='auto'>
                                            Offers a full-tuition scholarship to outstanding undergraduate students committed to studying engineering.
                                        </Col>
                                        <Col md='auto' className='text-md-end'>
                                            May 2018
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                </Container>
            </Row>
        </Container>
    </>
);

export default EducationPage;