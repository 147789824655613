import React from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Github, DisplayFill } from 'react-bootstrap-icons';

const SlimeProjectLinks = () => (
    <ButtonGroup aria-label='Project links' size='lg'>
        <Button variant='outline-secondary' href='https://amykwan.itch.io/the-secret-life-of-a-slime-extended'>
            <DisplayFill />
        </Button>
        <Button variant='outline-secondary' href='https://github.com/lisaye1999/Game-Design-Final-Slime'>
            <Github />
        </Button>
    </ButtonGroup>
);

export default SlimeProjectLinks;