import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {LinkContainer} from 'react-router-bootstrap';

const ProjectsList = ({projects}) => (
    <>
    {projects.map((project, i) => (
        <Col>
        <Card className="shadow h-100">
            <Card.Body className='d-flex flex-column'>
            <Card.Title>{project.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{project.blurb}</Card.Subtitle>
            <Card.Link className='stretched-link' href={`/project/${project.name}`}/>
            <LinkContainer to={`/project/${project.name}`}>
                <Button className='align-self-md-end mt-auto'>Read More</Button>
            </LinkContainer>
            </Card.Body>
        </Card>
        </Col>
    ))}
    </>
)

export default ProjectsList;
